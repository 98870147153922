import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { ListItem } from './eventCheckoutHeaderV1';
import { EventInfoItem } from '../eventInfoItem';
import { CheckoutType } from '../../../store/checkout/types';
import { useCheckoutHelper } from '../../../helpers/customHooks';
import { useUtils } from '../../../services/useUtils';

export const PriceInfo: FC<PriceInfoPropsType> = memo(({ checkout, isAuthorized, totalPrice }) => {
  const { t } = useTranslation();

  const { isInitiated } = useCheckoutHelper();
  const { getEmojiFlagByCurrency, round } = useUtils();

  if (!isInitiated(checkout)) {
    return null;
  }

  const { initiate, process } = checkout;

  if (isEmpty(initiate.paymentMethods)) {
    return null;
  }

  const { currency } = checkout.initiate.event;

  const pricePayByPoints = process ? round(process?.pricePayByPoints) : round(initiate?.pricePayByPoints);
  const pricePayByPointsTitle = `${pricePayByPoints} ${t('Checkout.PointsCurrency')} 
    ${getEmojiFlagByCurrency(currency)}`;

  let title = checkout?.process?.priceTotal
    ? `${checkout.process.priceTotal} ${currency}`
    : `${totalPrice} ${currency}`;

  const commission = process?.priceCommission ?? 0;
  let subtitle = commission > 0 ? `${commission} ${currency} Fees included` : 'Fees will be shown on the next step';

  if (checkout.usePoints) {
    title = `${initiate.priceTotal} ${currency} = ${pricePayByPointsTitle}`;
    subtitle =
      commission > 0
        ? `${commission} ${currency} ${t('Checkout.CommissionIncluded')}`
        : 'Fees will be shown on the next step';
  }

  return (
    <ListItem>
      <EventInfoItem icon="purse" themed title={title} subtitle={subtitle} />
    </ListItem>
  );
});

type PriceInfoPropsType = {
  checkout: CheckoutType;
  isAuthorized: boolean;
  totalPrice: number;
};
